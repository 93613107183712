import React from 'react';
import './Unmuted.css';

type UnmutedIconProps = {
  onClick: () => void;
  size?: number;
  color?: string;
  hoverColor?: string;
};

const UnmutedIcon: React.FC<UnmutedIconProps> = ({
  onClick,
  size = 40,
  color = '#B3B3B3',
  hoverColor = '#d0ff00',
}) => {
  return (
    <button
      className="unmuted-icon-button"
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="unmuted-icon"
      >
        <g clipPath="url(#clip0_245_17)">
          {/* Fixed gray background circle */}
          <rect width="40" height="40" rx="20" fill="#414141" />

          {/* Unmuted icon path, color controlled by props */}
          <path
            d="M27.4375 27.7969C27.1406 27.5938 27.1016 27.2266 27.3125 26.9062C28.5234 25.125 29.2891 22.8594 29.2891 20.3594C29.2891 17.8516 28.5234 15.6016 27.3125 13.8125C27.1016 13.4922 27.1406 13.125 27.4375 12.9219C27.7031 12.7266 28.0859 12.7969 28.2969 13.1172C29.6406 15.1016 30.4844 17.5938 30.4844 20.3594C30.4844 23.1172 29.6641 25.625 28.2969 27.6016C28.0859 27.9141 27.7031 27.9922 27.4375 27.7969ZM17.9375 27.2578C17.5703 27.2578 17.2656 27.1094 16.9141 26.7656L13.5781 23.6094C13.5312 23.5625 13.4609 23.5391 13.3828 23.5391H11.1484C10.0859 23.5391 9.51562 22.9609 9.51562 21.8281V18.9219C9.51562 17.7969 10.0859 17.2109 11.1484 17.2109H13.3828C13.4609 17.2109 13.5312 17.1875 13.5781 17.1406L16.9141 14.0156C17.3047 13.6328 17.5547 13.4766 17.9219 13.4766C18.4531 13.4766 18.8203 13.8906 18.8203 14.3984V26.3828C18.8203 26.8906 18.4531 27.2578 17.9375 27.2578ZM24.5078 25.7266C24.2188 25.5312 24.1797 25.1641 24.3984 24.8438C25.2344 23.625 25.7422 22.0234 25.7422 20.3594C25.7422 18.6953 25.25 17.0781 24.3984 15.875C24.1719 15.5547 24.2188 15.1875 24.5078 14.9922C24.7969 14.7969 25.1562 14.8672 25.3672 15.1641C26.375 16.5234 26.9531 18.4297 26.9531 20.3594C26.9531 22.2891 26.3672 24.1797 25.3672 25.5547C25.1562 25.8516 24.7969 25.9219 24.5078 25.7266ZM21.6094 23.6875C21.2891 23.4609 21.25 23.0859 21.4844 22.7578C21.9297 22.125 22.1953 21.2656 22.1953 20.3594C22.1953 19.4531 21.9375 18.5859 21.4844 17.9609C21.2422 17.6406 21.2891 17.25 21.6094 17.0312C21.8672 16.8438 22.2344 16.9062 22.4453 17.2109C23.0469 18.0078 23.4062 19.1641 23.4062 20.3594C23.4062 21.5547 23.0469 22.7188 22.4453 23.5078C22.2344 23.8125 21.8672 23.8672 21.6094 23.6875Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_245_17">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {/* Inline style block to replicate the same hover effect */}
      <style>{`
        .unmuted-icon-button:hover .unmuted-icon path {
          fill: ${hoverColor};
        }
      `}</style>
    </button>
  );
};

export default UnmutedIcon;
