// src/services/webSocketService.ts

import { io, Socket } from 'socket.io-client';
import { getWebSocketURL } from '../utils/getBaseURL';
import {
  RoomDetails,
  Player,
  User,
  VotingStatus,
  VotingBallot,
} from '../types';

/**
 * Define payloads for events emitted to the server.
 */
export type EmitEventPayloads = {
  joinGameRoom: string; // roomCode
  joinUserRoom: string; // userId
  redirectReplay: { roomCode: string };
  redirectLibrary: { roomCode: string };
  votingStatusUpdate: { roomCode: string; votingStatusData: VotingStatus };
  votingBallotUpdate: { roomCode: string; votingBallotData: VotingBallot };
  requestVotingState: { roomCode: string };

  // Add other emitted events as needed
};

/**
 * Define payloads for events received from the server.
 */
export type ReceiveEventPayloads = {
  roomCreated: RoomDetails;
  roomUpdate: RoomDetails;
  playerUpdate: { playerId: string; player: Player };
  userUpdate: User;
  redirectReplay: void; // No payload
  redirectLibrary: void; // No payload
  votingStatusUpdate: VotingStatus;
  votingBallotUpdate: VotingBallot;
  // Add other received events as needed
};

/**
 * Type alias for a generic event callback function.
 */
type EventCallback<T> = (data: T) => void;

/**
 * WebSocketService is a singleton class to manage WebSocket connections.
 * It wraps the Socket.IO client to provide a consistent API for emitting and listening to events.
 */
class WebSocketService {
  private static instance: WebSocketService; // Singleton instance
  public socket: Socket; // Socket.IO client instance
  private connectionPromise: Promise<void>; // Tracks connection status

  // Map to track registered listeners, grouped by event name, with strict types
  private registeredListeners: Map<
    keyof ReceiveEventPayloads,
    Set<EventCallback<ReceiveEventPayloads[keyof ReceiveEventPayloads]>>
  > = new Map();

  /**
   * Private constructor initializes the Socket.IO client and event listeners.
   */
  private constructor() {
    this.socket = io(getWebSocketURL(), {
      transports: ['websocket'], // Enforce WebSocket transport
      withCredentials: true, // Send credentials (e.g., cookies, auth headers)
      reconnection: true, // Enable automatic reconnections
      reconnectionAttempts: Infinity, // Unlimited reconnection attempts
      reconnectionDelay: 1000, // Start with a 1-second delay
      reconnectionDelayMax: 10000, // Cap delay at 10 seconds
      randomizationFactor: 0.5, // Add jitter to avoid reconnection storms
    });

    this.connectionPromise = this.createConnectionPromise();
    this.initializeListeners();
  }

  /**
   * Returns the singleton instance of WebSocketService.
   */
  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  /**
   * Creates a promise to track the connection status.
   * Resolves on successful connection or rejects on timeout/error.
   */
  private createConnectionPromise(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const timeout = setTimeout(() => {
        if (!this.socket.connected) {
          console.error('[WebSocketService] Connection timeout');
          reject(new Error('[WebSocketService] Connection timeout'));
        }
      }, 10000);

      const cleanup = () => {
        clearTimeout(timeout);
        this.socket.off('connect', onConnect);
        this.socket.off('connect_error', onError);
      };

      const onConnect = () => {
        cleanup();
        console.log('[WebSocketService] Connected to WebSocket');
        resolve();
      };

      const onError = (error: Error) => {
        cleanup();
        console.error('[WebSocketService] Connection error:', error.message);
        reject(error);
      };

      this.socket.once('connect', onConnect);
      this.socket.once('connect_error', onError);
    }).catch((error) => {
      console.error(
        '[WebSocketService] Failed to connect:',
        error instanceof Error ? error.message : error,
      );
      throw error;
    });
  }

  /**
   * Initializes global event listeners for connection management.
   */
  private initializeListeners(): void {
    this.socket.on('connect', () => {
      console.log('[WebSocketService] Connected:', this.socket.id);
      this.connectionPromise = this.createConnectionPromise(); // Reset the connection promise
    });

    this.socket.on('disconnect', (reason) => {
      console.warn(`[WebSocketService] Disconnected: ${reason}`);
      if (reason === 'io server disconnect') {
        console.log(
          '[WebSocketService] Reconnecting after server disconnect...',
        );
        this.socket.connect();
      }
    });

    this.socket.on('connect_error', (error) => {
      console.error('[WebSocketService] Connection error:', error.message);
    });

    this.socket.on('reconnect_attempt', (attempt) => {
      console.log(`[WebSocketService] Reconnection attempt #${attempt}`);
    });

    this.socket.on('reconnect_error', (error) => {
      console.error('[WebSocketService] Reconnection error:', error.message);
    });

    this.socket.on('reconnect_failed', () => {
      console.error('[WebSocketService] Reconnection failed');
    });
  }

  /**
   * Waits for the WebSocket connection to be established.
   */
  public async waitForConnection(): Promise<void> {
    try {
      await this.connectionPromise;
    } catch (error) {
      console.error(
        '[WebSocketService] Error during WebSocket connection:',
        error instanceof Error ? error.message : error,
      );
    }
  }

  /**
   * Emits an event to the server with the specified data.
   * @param event - The event name.
   * @param data - The payload to send with the event.
   */
  public emitEvent<K extends keyof EmitEventPayloads>(
    event: K,
    data: EmitEventPayloads[K],
  ): void {
    this.socket.emit(event, data);
    console.log(`[WebSocketService] Emitted event: ${event}`, data);
  }

  /**
   * Ensures a Set exists for the specified event.
   * Dynamically creates a Set if none exists.
   * @param event - The event name.
   * @returns The Set of listeners for the given event.
   */
  private getOrCreateListenerSet<K extends keyof ReceiveEventPayloads>(
    event: K,
  ): Set<EventCallback<ReceiveEventPayloads[K]>> {
    if (!this.registeredListeners.has(event)) {
      // Dynamically create and set the listener set for the event
      this.registeredListeners.set(event, new Set());
    }

    // Safely retrieve the Set and cast its type to match the specific event
    return this.registeredListeners.get(event) as Set<
      EventCallback<ReceiveEventPayloads[K]>
    >;
  }

  /**
   * Registers a listener for a specific event.
   * Ensures no duplicate listeners are registered.
   * @param event - The event name.
   * @param callback - The callback to execute when the event is received.
   */
  public onEvent<K extends keyof ReceiveEventPayloads>(
    event: K,
    callback: EventCallback<ReceiveEventPayloads[K]>,
  ): void {
    const listeners = this.getOrCreateListenerSet(event);

    // Add the callback to the Set if not already registered
    if (!listeners.has(callback)) {
      listeners.add(callback);
      const wrappedCallback = (data: ReceiveEventPayloads[K]) => {
        callback(data); // Execute the original callback
      };
      this.socket.on(event as string, wrappedCallback);
      console.log(`[WebSocketService] Registered listener for event: ${event}`);
    }
  }

  /**
   * Removes a listener for a specific event.
   * Cleans up empty listener sets.
   * @param event - The event name.
   * @param callback - The callback to remove.
   */
  public offEvent<K extends keyof ReceiveEventPayloads>(
    event: K,
    callback: EventCallback<ReceiveEventPayloads[K]>,
  ): void {
    // Fetch the specific Set for the given event
    const listeners = this.registeredListeners.get(event) as
      | Set<EventCallback<ReceiveEventPayloads[K]>>
      | undefined;

    // Remove the callback if it exists
    if (listeners && listeners.has(callback)) {
      listeners.delete(callback);

      // Remove the listener from Socket.IO
      this.socket.off(event as string, callback);

      console.log(`[WebSocketService] Removed listener for event: ${event}`);

      // Clean up the Map entry if no listeners remain
      if (listeners.size === 0) {
        this.registeredListeners.delete(event); // Ensure memory cleanup for empty event sets
        console.log(
          `[WebSocketService] Cleaned up empty listener set for event: ${event}`,
        );
      }
    }
  }

  /**
   * Joins a specific WebSocket game room.
   * @param roomCode - The room code to join.
   */
  public joinGameRoom(roomCode: string): void {
    this.emitEvent('joinGameRoom', roomCode);
    console.log(`[WebSocketService] Joined game room: ${roomCode}`);
  }

  /**
   * Joins a user-specific WebSocket room.
   * @param userId - The user ID to join.
   */
  public joinUserRoom(userId: string | null | undefined): void {
    if (!userId) {
      console.warn(
        '[WebSocketService] Cannot join user room: User ID is null or undefined',
      );
      return; // Skip joining if no valid user ID
    }
    this.emitEvent('joinUserRoom', userId);
    console.log(`[WebSocketService] Joined user room: ${userId}`);
  }

  /**
   * Leaves a specific WebSocket room.
   * @param roomCode - The room code to leave.
   */
  public leaveRoom(roomCode: string): void {
    this.socket.emit('leaveRoom', roomCode);
    console.log(`[WebSocketService] Left room: ${roomCode}`);
  }

  /**
   * Emits a voting status update to the server.
   * @param roomCode - The room code to which the voting update belongs.
   * @param votingStatusData - The voting status update data.
   */
  public sendVotingStatusUpdate(
    roomCode: string,
    votingStatusData: VotingStatus,
  ): void {
    try {
      this.emitEvent('votingStatusUpdate', { roomCode, votingStatusData });
      console.log(
        `[WebSocketService] Sent voting status update for room: ${roomCode}`,
      );
    } catch (error) {
      console.error(
        '[WebSocketService] Failed to send voting status update:',
        error,
      );
    }
  }

  /**
   * Emits a voting ballot update to the server.
   * @param roomCode - The room code to which the voting update belongs.
   * @param votingBallotData - The voting ballot update data.
   */
  public sendVotingBallotUpdate(
    roomCode: string,
    votingBallotData: VotingBallot,
  ): void {
    try {
      this.emitEvent('votingBallotUpdate', { roomCode, votingBallotData });
      console.log(
        `[WebSocketService] Sent voting ballot update for room: ${roomCode}`,
      );
    } catch (error) {
      console.error(
        '[WebSocketService] Failed to send voting ballot update:',
        error,
      );
    }
  }

  /**
   * Listens for voting status updates from the server.
   * @param callback - Callback function to handle the voting status update.
   */
  public onVotingStatusUpdate(
    callback: (votingStatusData: VotingStatus) => void,
  ): () => void {
    const wrappedCallback = (data: VotingStatus) => {
      console.log('[WebSocketService] Received voting status update:', data);
      callback(data);
    };

    this.socket.on('votingStatusUpdate', wrappedCallback);
    console.log(
      '[WebSocketService] Registered listener for voting status updates',
    );

    // Return a cleanup function to remove the listener
    return () => {
      this.socket.off('votingStatusUpdate', wrappedCallback);
      console.log(
        '[WebSocketService] Removed listener for voting status updates',
      );
    };
  }

  /**
   * Listens for voting ballot updates from the server.
   * @param callback - Callback function to handle the voting ballot update.
   */
  public onVotingBallotUpdate(
    callback: (votingBallotData: VotingBallot) => void,
  ): () => void {
    const wrappedCallback = (data: VotingBallot) => {
      console.log('[WebSocketService] Received voting ballot update:', data);
      callback(data);
    };

    this.socket.on('votingBallotUpdate', wrappedCallback);
    console.log(
      '[WebSocketService] Registered listener for voting ballot updates',
    );

    // Return a cleanup function to remove the listener
    return () => {
      this.socket.off('votingBallotUpdate', wrappedCallback);
      console.log(
        '[WebSocketService] Removed listener for voting ballot updates',
      );
    };
  }
}

export default WebSocketService;
