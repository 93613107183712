import React, { useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import {
  languageOptions,
  hostStyleOptions,
  contentStyleOptions,
} from '../utils/userSettingsOptions';
import axiosInstance from '../utils/axiosConfig';
import './UserSettings.css';
import { useNavigate } from 'react-router-dom';

const UserSettings: React.FC = () => {
  const { user, loading, setUser } = useUserContext(); // Access user data and UserContext updater
  const [language, setLanguage] = useState(user?.userSettings.language || '');
  const [hostAccent, setHostAccent] = useState(
    user?.userSettings.hostAccent || '',
  );
  const [hostStyle, setHostStyle] = useState(
    user?.userSettings.hostStyle || '',
  );
  const [contentStyle, setContentStyle] = useState(
    user?.userSettings.contentStyle || '',
  );
  const navigate = useNavigate();

  if (loading) return <div>Loading...</div>;
  if (!user) return <div>No user data available.</div>;

  const logEvent = (action: string, label: string) => {
    window.gtag?.('event', action, {
      event_category: 'user_settings',
      event_label: label,
    });
  };

  const handleLogout = async () => {
    try {
      logEvent('button_click', 'Sign Out');
      await axiosInstance.post('/api/logout');
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleSupport = () => {
    logEvent('button_click', 'Contact Support');
    const email = 'poppy@airheadgames.com';
    const subject = encodeURIComponent('Support Request');

    // Create a mailto URL
    const mailtoLink = `mailto:${email}?subject=${subject}`;

    // Open the user's email client
    window.location.href = mailtoLink;
  };

  const handleUpdate = async (key: string, value: string) => {
    try {
      logEvent('dropdown_change', key);
      await axiosInstance.post('/api/update-user', {
        $set: { [`userSettings.${key}`]: value },
      });

      // Update the UserContext with the new value
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              userSettings: {
                ...prevUser.userSettings,
                [key]: value,
              },
            }
          : null,
      );
      console.log(`Updated ${key} to: ${value}`);
    } catch (error) {
      console.error(`Failed to update ${key}:`, error);
    }
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    handleUpdate('language', newLanguage);
  };

  const handleHostAccentChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newAccent = event.target.value;
    setHostAccent(newAccent);
    handleUpdate('hostAccent', newAccent);
  };

  const handleHostStyleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newHostStyle = event.target.value;
    setHostStyle(newHostStyle);
    handleUpdate('hostStyle', newHostStyle);
  };

  const handleContentStyleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newContentStyle = event.target.value;
    setContentStyle(newContentStyle);
    handleUpdate('contentStyle', newContentStyle);
  };

  return (
    <div className="user-settings-container">
      <h2 className="user-settings-title">Settings</h2>

      <div>
        <h3 className="user-settings-section">User</h3>
        <p className="user-settings-option">
          <strong>Email:</strong> {user.email}
        </p>
        <a onClick={handleSupport} className="user-settings-option-support">
          Contact Support &#x2197;
        </a>
        <a onClick={handleLogout} className="user-settings-option-signout">
          Sign Out
        </a>
      </div>

      <div>
        <h3 className="user-settings-section">Preferences</h3>

        <div className="user-settings-option">
          <strong>Language:</strong>
          <select
            value={language}
            onChange={handleLanguageChange}
            className="user-settings-dropdown"
          >
            {languageOptions
              .filter((option) => !option.disabled) // Exclude disabled options
              .map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Host Accent:</strong>
          <select
            value={hostAccent}
            onChange={handleHostAccentChange}
            className="user-settings-dropdown"
          >
            {languageOptions
              .find((option) => option.value === language)
              ?.accents.map((accent) => (
                <option key={accent.value} value={accent.value}>
                  {accent.label}
                </option>
              )) || <option>No accents available</option>}
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Host Style:</strong>
          <select
            value={hostStyle}
            onChange={handleHostStyleChange}
            className="user-settings-dropdown"
          >
            {hostStyleOptions
              .filter((option) => !option.disabled) // Exclude disabled options
              .map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>

        <div className="user-settings-option">
          <strong>Content Style:</strong>
          <select
            value={contentStyle}
            onChange={handleContentStyleChange}
            className="user-settings-dropdown"
          >
            {contentStyleOptions
              .filter((option) => !option.disabled) // Exclude disabled options
              .map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <h3 className="user-settings-section">Subscription</h3>
        {user.subscription?.currentPeriodStart && (
          <p className="user-settings-option">
            <strong>Start Date:</strong>{' '}
            {new Date(
              user.subscription.currentPeriodStart,
            ).toLocaleDateString()}
          </p>
        )}
        {user.subscription?.currentPeriodEnd && (
          <p className="user-settings-option">
            <strong>End Date:</strong>{' '}
            {new Date(user.subscription.currentPeriodEnd).toLocaleDateString()}
          </p>
        )}
        <a
          href="https://billing.stripe.com/p/login/7sI29i8XE5vwd9ufYY"
          target="_blank"
          rel="noopener noreferrer"
          className="user-settings-option"
          onClick={() => logEvent('button_click', 'Manage Subscription')}
        >
          Manage Subscription and Billing &#x2197;
        </a>
      </div>
    </div>
  );
};

export default UserSettings;
