import React from 'react';
import Wordmark from '../components/Wordmark';
import './About.css';
import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { Game } from '../types';
import { getEnabledLanguageAndAccentCounts } from '../utils/userSettingsOptions';
import HamburgerMenu from '../components/HamburgerMenu';

const About: React.FC = () => {
  const [activeGamesCount, setActiveGamesCount] = useState(0);
  const { languagesCount, accentsCount } = getEnabledLanguageAndAccentCounts();

  useEffect(() => {
    const fetchActiveGamesCount = async () => {
      try {
        const response = await axiosInstance.get<Game[]>(`/api/games`, {
          withCredentials: true,
        });

        // Count the active games directly
        const count = response.data.filter((game) => game.isActive).length;

        // Update the count in state
        setActiveGamesCount(count);
      } catch (error) {
        console.error('Error fetching active games count:', error);
      }
    };

    fetchActiveGamesCount();
  }, []);

  return (
    <div className="about-page">
      <Wordmark />

      <div className="about-button">
        <HamburgerMenu />
      </div>
      <div className="about-airhead">
        <section>
          <h1>Why</h1>
          <p>
            Bringing people together fosters meaningful relationships and
            enriches our lives. In the dawning era of AI, this amazing
            technology can create shared experiences to help us embrace
            community, laughter, joy, and everything else making us human.
          </p>
        </section>

        <section>
          <h1>How</h1>
          <p>
            Airhead Games are AI-powered party games. All content is dynamically
            generated by AI, delivering high replayability with something fresh
            and unpredictable every session. The AI game show host facilitates
            play and reacts to players and their choices.
          </p>
        </section>

        <section>
          <h1>What</h1>
          <p>
            The current Game Library includes {activeGamesCount} games which can
            be played in {languagesCount} language and {accentsCount} accents.
            Additional games and languages will be added soon. Host styles and
            AI content safety settings are configurable and can be tailored at
            any time to your preferences.
          </p>
        </section>

        <section>
          <h1>Who</h1>
          <p>
            Airhead Games is built by an independent developer passionate about
            bringing families, friends, and colleagues together for a great
            time.
            <br></br>
            <br></br>
            While there is no limit to the number of players who can join a
            game, at least one player must have a subscription to access the
            Game Library. Charging a subscription covers the cost of the
            limitless entertainment associated with AI-generated content and
            continued game innovation.
          </p>
        </section>

        <section>
          <h1>Where</h1>
          <p>
            Airhead Games is only accessible through the web application at this
            time, but will be available through all major platforms in the
            future.
          </p>
        </section>

        <section>
          <h1>Contact</h1>
          <p>
            Need help? Found a bug? Have an idea or feedback? Contact me at{' '}
            <a href="mailto:poppy@airheadgames.com">poppy@airheadgames.com</a>.
          </p>
        </section>

        <div className="about-spacer"></div>
      </div>
    </div>
  );
};

export default About;
