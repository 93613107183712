import React, { useEffect, useState, useRef } from 'react';
import { useRoomDetails } from '../contexts/RoomDetailsContext';
import PlayerContainer from '../components/PlayerContainer';
import Form from '../components/Form';
import './Room.css';
import '../components/Form.css';
import Loader from '../components/Loader';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate
import axiosInstance from '../utils/axiosConfig';
import { usePlayerInfoContext } from '../contexts/PlayerInfoContext';
import { useSpeech } from '../contexts/SpeechContext'; // Import SpeechContext
import AudioControl from '../components/AudioControl';
import { useAudioControl } from '../contexts/AudioControlContext'; // Import AudioControlContext
import createHostCommentary from '../services/createHostCommentary';
import { getGameDescriptionForRoom } from '../utils/gameDescriptionMap';

const Room: React.FC = () => {
  const { roomDetails } = useRoomDetails(); // Context provides room details
  const { players } = usePlayerInfoContext(); // Context provides players
  const [qrCode, setQrCode] = useState<string | undefined>(undefined);
  const roomCode = roomDetails?.roomCode || '';
  const [gameStarting, setGameStarting] = useState(false); // In this case we don't rely on the form button loading because we need the state to persist for longer than just form submission
  const [startGameRequested, setStartGameRequested] = useState(false); // State to track Start Game when speaking still
  const prevPlayersRef = useRef<string[]>([]); // Store previous players' names
  const navigate = useNavigate(); // Initialize navigate function
  const { queueSpeech, speechRef, isSpeaking } = useSpeech(); // Use SpeechContext for TTS
  const { isMuted, mute, unmute } = useAudioControl(); // Mute/unmute from shared context
  const [isGeneratingCommentary, setIsGeneratingCommentary] =
    useState<boolean>(false);

  // Add a state to hold the pre-generated start commentary
  const [gameStartCommentary, setGameStartCommentary] = useState<string | null>(
    null,
  );
  const hasGeneratedStartCommentaryRef = useRef<boolean>(false);

  // Function to show game link if on screen and game has already started
  const handleGoToGame = () => {
    if (roomDetails?.roomCode) {
      navigate(`/game/${roomDetails?.roomCode}`);
    }
  };

  // Fetch the QR code for the room based on room code
  useEffect(() => {
    const fetchQRCode = async () => {
      if (roomDetails?.roomCode) {
        try {
          const response = await axiosInstance.get(
            `/api/qr/${roomDetails.roomCode}`,
            { withCredentials: true },
          );
          setQrCode(response.data); // Store QR code data in state
        } catch (error) {
          console.error('Error fetching QR code:', error);
        }
      }
    };
    fetchQRCode();
  }, [roomDetails?.roomCode]);

  // Starts game if host is no longer speaking
  useEffect(() => {
    const startGameAfterSpeech = async () => {
      if (startGameRequested && !isSpeaking && !isGeneratingCommentary) {
        try {
          if (roomDetails?.roomCode) {
            const response = await axiosInstance.post('/api/startGame', {
              roomCode: roomDetails.roomCode,
            });
            console.log('Game started:', response.data);
            navigate(`/game/${roomDetails.roomCode}`);
          }
        } catch (error) {
          console.error('Error starting game:', error);
        } finally {
          setGameStarting(false);
          setStartGameRequested(false);
        }
      }
    };

    startGameAfterSpeech();
  }, [
    isSpeaking,
    isGeneratingCommentary,
    startGameRequested,
    roomDetails?.roomCode,
    navigate,
  ]);

  // Pre-generate the game start commentary so it can be queued later
  useEffect(() => {
    const generateGameStartCommentary = async () => {
      if (
        !hasGeneratedStartCommentaryRef.current &&
        roomCode &&
        roomDetails?.gameId
      ) {
        // Get the game description based on the gameId
        const gameDescription = getGameDescriptionForRoom(roomDetails.gameId);

        const gameNotes = `All players have joined the room for ${roomDetails?.gameName}, the game where ${gameDescription}. Introduce yourself as the host, Poppy, and announce the game is starting.`;
        try {
          // Set isGeneratingCommentary to true before starting
          setIsGeneratingCommentary(true);
          const { language, hostAccent, hostStyle, contentStyle } =
            roomDetails?.roomSettings || {};

          const commentaryText = await createHostCommentary({
            roomCode,
            language: language || 'en',
            hostAccent: hostAccent || 'en-US',
            hostStyle: hostStyle || 'standard',
            contentStyle: contentStyle || 'standard',
            gameNotes,
          });
          setGameStartCommentary(commentaryText || '');
          hasGeneratedStartCommentaryRef.current = true;
        } catch (error) {
          console.error('Error generating host commentary:', error);
        } finally {
          // Set back to false after finishing
          setIsGeneratingCommentary(false);
        }
      }
    };

    generateGameStartCommentary();
  }, [
    roomCode,
    roomDetails?.roomSettings,
    roomDetails?.gameName,
    roomDetails?.gameId,
  ]);

  // Function to start the game
  const handleStartGame = async () => {
    setGameStarting(true);

    // Queue the pre-generated gameStartCommentary
    if (gameStartCommentary) {
      const { hostAccent } = roomDetails?.roomSettings || {};
      queueSpeech(gameStartCommentary, hostAccent || 'en-US');
    }

    setStartGameRequested(true);
  };

  // Track and announce new players joining
  useEffect(() => {
    const announceNewPlayers = async () => {
      const newPlayers = players.filter(
        (player) => !prevPlayersRef.current.includes(player.playerName),
      );

      if (newPlayers.length > 0) {
        for (const newPlayer of newPlayers) {
          const gameNotes = `${newPlayer.playerName} has joined the room. Greet the new person using their name and just a few words.`;

          try {
            // Set isGeneratingCommentary to true before starting
            setIsGeneratingCommentary(true);
            const { language, hostAccent, hostStyle, contentStyle } =
              roomDetails?.roomSettings || {};

            const commentaryText = await createHostCommentary({
              roomCode,
              language: language || 'en',
              hostAccent: hostAccent || 'en-US',
              hostStyle: hostStyle || 'standard',
              contentStyle: contentStyle || 'standard',
              gameNotes,
            });

            queueSpeech(commentaryText, hostAccent);
          } catch (error) {
            console.error('Error generating host commentary:', error);
          } finally {
            // Set back to false after finishing
            setIsGeneratingCommentary(false);
          }
        }

        // Update the previous players' names after processing all
        prevPlayersRef.current = players.map((player) => player.playerName);
      }
    };

    // Call the async function
    announceNewPlayers();
  }, [players, roomDetails?.roomSettings, roomCode, queueSpeech]);

  // Determine the button label based on activeGame
  const isGameActive = roomDetails?.activeGame;
  const enoughPlayers = Array.isArray(players) && players.length > 1; // At least two players to start game
  const buttonLabel = isGameActive
    ? 'Game in Progress'
    : enoughPlayers
      ? 'Start Game'
      : 'Waiting for Players';

  const isButtonDisabled = isGameActive || !enoughPlayers || gameStarting;

  return (
    <div className="room-container">
      <header>
        <h1 className="room-game-headline">
          {roomDetails?.gameName || 'Unknown Game'}
        </h1>
        <div className="room-go-back">
          <Link to="/game-library"> ← Back to Game Library</Link>
        </div>
        {/* Audio Controls */}
        <AudioControl
          isMuted={isMuted}
          onToggleMute={isMuted ? unmute : mute}
          speechRef={speechRef}
        />
      </header>

      <main className="room-layout">
        <div className="room-players-container">
          {/* Render PlayerContainer with fetched player details */}
          {
            <PlayerContainer
              players={players}
              layoutType="grid"
              avatarSize={160}
              avatarPreset={'standard-floating'}
            />
          }
        </div>
        <div className="room-form-container">
          {/* Render Form with QR code and game start button */}
          <Form
            className="form-container--room"
            buttonLabel={gameStarting ? <Loader loading={true} /> : buttonLabel}
            onSubmit={handleStartGame}
            headerText="SCAN TO JOIN"
            qrCode={qrCode}
            customContent={
              <div>
                or use code{' '}
                <span style={{ color: '#d0ff00', fontWeight: 'bold' }}>
                  {roomDetails?.roomCode}
                </span>{' '}
                at <br />
                airheadgames.com
              </div>
            }
            disabledButton={isButtonDisabled}
          />
          {isGameActive && (
            <a onClick={handleGoToGame} className="go-to-game-link">
              Go To Game
            </a>
          )}
        </div>
      </main>
    </div>
  );
};

export default Room;
