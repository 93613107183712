// utils/gameDescriptionMap
const gameDescriptionMapping: Record<string, string> = {
  '6701bc6c35797c2928318643':
    'Players take turns choosing open-ended prompts. Everyone submits a response, then votes on the best one—just not their own. Earn 1 point when your answer is chosen by another player and 3 points if the prompt-picker chooses your answer. The person with the most points after three rounds wins the game!',
  '6763bad2f4c6d095f2dd91d0':
    'Players take turns picking Ego Awards and voting on which of the other players is most deserving. The first player to earn five awards is the biggest Ego Maniac and wins the game!',
};

/**
 * Retrieves the bucket name for a given game ID.
 * @param gameId - The unique identifier for the game.
 * @returns The corresponding bucket name.
 * @throws Error if the game ID is not found in the mapping.
 */
export function getGameDescriptionForRoom(gameId: string): string {
  const gameDescription = gameDescriptionMapping[gameId];
  if (!gameDescription) {
    throw new Error(`No game description found for gameId: ${gameId}`);
  }
  return gameDescription;
}
