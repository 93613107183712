import React, { ReactNode } from 'react';
import PlayerCard from './PlayerCard';
import './PlayerContainer.css';
import { Player } from '../types'; // Ensure the Player type is imported from the correct location

interface PlayerContainerProps {
  players: Player[]; // Array of players with full details
  layoutType: 'grid' | 'single-row'; // Layout type for the container
  avatarSize: number; // Size of the avatar in pixels
  avatarPreset?:
    | 'standard-static'
    | 'standard-floating'
    | 'pop-head'
    | 'scorebar-reveal';
  avatarVisibleLayers?: {
    border?: boolean;
    balloon?: boolean;
    string?: boolean;
    body?: boolean;
    explosion?: boolean;
    scorebar?: boolean;
    score?: boolean;
  };
  // Optional custom content to render for each player
  customContent?: (player: Player) => ReactNode;

  // Prop for receiving scale dictionary
  playerBalloonScales?: Record<string, number>;

  // Prop for avatar presets, keyed by playerId
  playerAvatarPresets?: Record<
    string,
    'standard-static' | 'standard-floating' | 'pop-head' | 'scorebar-reveal'
  >;

  // New optional scaling props
  avatarBorderScale?: number;
  avatarBalloonScale?: number;
  avatarStringScale?: number;
  avatarBodyScale?: number;
  avatarExplosionScale?: number;
  avatarScorebarScale?: number;
}

const PlayerContainer: React.FC<PlayerContainerProps> = ({
  players,
  layoutType,
  avatarSize,
  avatarPreset = 'standard-static', // Default preset
  avatarVisibleLayers, // Visibility layers passed down
  customContent,
  playerBalloonScales,
  playerAvatarPresets,
}) => {
  // Set dynamic grid columns based on layout type
  const gridColumns =
    layoutType === 'grid'
      ? `repeat(${Math.ceil(Math.sqrt(players.length))}, 1fr)`
      : `repeat(${players.length}, 1fr)`;

  return (
    <div
      className={`players-container ${layoutType}`}
      style={{ gridTemplateColumns: gridColumns }}
    >
      {players.map((player) => {
        // Look up the balloon scale for the current player
        const balloonScale = playerBalloonScales?.[player._id] ?? 1;

        // Look up the avatar preset for the current player
        const preset = playerAvatarPresets?.[player._id] ?? avatarPreset;

        return (
          <div key={player._id} className="player-card-with-custom-content">
            {customContent && (
              <div className="player-card-container-custom-content">
                {customContent(player)}
              </div>
            )}
            <PlayerCard
              player={player}
              avatarSize={avatarSize}
              avatarPreset={preset}
              avatarVisibleLayers={avatarVisibleLayers}
              // Pass a SINGLE number (balloonScale) instead of the entire dictionary
              avatarBalloonScale={balloonScale}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PlayerContainer;
