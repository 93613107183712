import { ReactNode } from 'react';
import { RoomDetailsProvider } from './RoomDetailsContext';
import { PlayerInfoProvider } from './PlayerInfoContext';
import { VotingDetailsProvider } from './VotingDetailsContext';

/**
 * CombinedRoomProviders conditionally wraps room-specific contexts based on roomCode.
 */
interface CombinedRoomProvidersProps {
  children: ReactNode;
  roomCode?: string;
}

export const CombinedRoomProviders = ({
  children,
  roomCode,
}: CombinedRoomProvidersProps) => {
  // If no roomCode is provided, return children directly (no wrapping).
  if (!roomCode) return <>{children}</>;

  // Wrap room-specific providers only when roomCode exists.
  return (
    <RoomDetailsProvider roomCode={roomCode}>
      <PlayerInfoProvider>
        <VotingDetailsProvider roomCode={roomCode}>
          {children}
        </VotingDetailsProvider>
      </PlayerInfoProvider>
    </RoomDetailsProvider>
  );
};
