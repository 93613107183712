import React, { createContext, useContext, useEffect, useRef } from 'react';
import { getSharedAsset } from '../utils/getSharedAsset';
import { useAudioControl } from './AudioControlContext';

interface SoundEffectsContextProps {
  playSoundEffect: (soundKey: keyof SoundEffects) => void;
}

interface SoundEffects {
  timerEndSound: HTMLAudioElement | null;
  timerBeepSound: HTMLAudioElement | null;
  bubblePopSound: HTMLAudioElement | null;
  powerUpSound: HTMLAudioElement | null;
  grandEntranceSound: HTMLAudioElement | null;
}

const SoundEffectsContext = createContext<SoundEffectsContextProps | undefined>(
  undefined,
);

export const SoundEffectsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isMuted } = useAudioControl(); // Respect global audio control context
  const soundEffectRefs = useRef<SoundEffects>({
    timerEndSound: null,
    timerBeepSound: null,
    bubblePopSound: null,
    powerUpSound: null,
    grandEntranceSound: null,
  });

  useEffect(() => {
    const prefetchSoundEffects = async () => {
      try {
        const timerEndUrl = await getSharedAsset('sound-effects/timer_end.wav');
        const timerBeepUrl = await getSharedAsset(
          'sound-effects/timer_beep.wav',
        );
        const bubblePopUrl = await getSharedAsset(
          'sound-effects/bubble_pop.wav',
        );
        const powerUpUrl = await getSharedAsset('sound-effects/power_up.wav');
        const grandEntranceUrl = await getSharedAsset(
          'sound-effects/grand_entrance.wav',
        );

        soundEffectRefs.current.timerEndSound = new Audio(timerEndUrl);
        soundEffectRefs.current.timerBeepSound = new Audio(timerBeepUrl);
        soundEffectRefs.current.bubblePopSound = new Audio(bubblePopUrl);
        soundEffectRefs.current.powerUpSound = new Audio(powerUpUrl);
        soundEffectRefs.current.grandEntranceSound = new Audio(
          grandEntranceUrl,
        );

        console.log('[INFO] Sound effects prefetched successfully');
      } catch (error) {
        console.error('[ERROR] Failed to prefetch sound effects:', error);
      }
    };

    prefetchSoundEffects();
  }, []);

  const playSoundEffect = (soundKey: keyof SoundEffects) => {
    if (isMuted) {
      console.log(`[DEBUG] Audio muted, not playing: ${soundKey}`);
      return;
    }

    const soundEffect = soundEffectRefs.current[soundKey];
    if (soundEffect) {
      soundEffect.currentTime = 0; // Reset to start for replaying
      soundEffect
        .play()
        .catch((err) =>
          console.error(
            `[ERROR] Failed to play sound effect: ${soundKey}`,
            err,
          ),
        );
    } else {
      console.warn(`[WARN] Sound effect not loaded: ${soundKey}`);
    }
  };

  return (
    <SoundEffectsContext.Provider value={{ playSoundEffect }}>
      {children}
    </SoundEffectsContext.Provider>
  );
};

export const useSoundEffects = (): SoundEffectsContextProps => {
  const context = useContext(SoundEffectsContext);
  if (!context) {
    throw new Error(
      'useSoundEffects must be used within a SoundEffectsProvider',
    );
  }
  return context;
};
