import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { VotingStatus, VotingBallot, Nominations } from '../types';
import { useWebSocket } from './WebSocketContext';

interface VotingDetailsContextType {
  votingOpen: boolean;
  toggleVotingOpen: (isOpen: boolean) => void;
  nominations: Nominations[];
  setNominations: React.Dispatch<React.SetStateAction<Nominations[]>>;
  addVote: (voterId: string, nominatedId: string) => void;
  removeVote: (voterId: string, nominatedId: string) => void;
  switchVote: (
    voterId: string,
    oldNominatedId: string | null,
    newNominatedId: string,
  ) => void;
  resetNominations: () => void;
  votingStatusTimestamp: number;
  votingBallotTimestamp: number;
}

const VotingDetailsContext = createContext<
  VotingDetailsContextType | undefined
>(undefined);

export const VotingDetailsProvider: React.FC<{
  children: React.ReactNode;
  roomCode: string;
}> = ({ children, roomCode }) => {
  const {
    onVotingStatusUpdate,
    onVotingBallotUpdate,
    sendVotingStatusUpdate,
    sendVotingBallotUpdate,
    joinGameRoom,
    requestVotingState,
    connectionStatus,
  } = useWebSocket();
  const [votingOpen, setVotingOpen] = useState<boolean>(false);
  const [nominations, setNominations] = useState<Nominations[]>([]);
  const [votingStatusTimestamp, setVotingStatusTimestamp] = useState<number>(0);
  const [votingBallotTimestamp, setVotingBallotTimestamp] = useState<number>(0);

  /**
   * **Request Latest Voting State on Mount & Reconnection**
   * This ensures that when the component mounts or reconnects,
   * it fetches the latest voting state.
   */
  useEffect(() => {
    if (roomCode && connectionStatus === 'connected') {
      console.log(
        `[VotingDetailsProvider] Requesting voting state for room: ${roomCode}`,
      );
      requestVotingState(roomCode);
    }
  }, [roomCode, connectionStatus, requestVotingState]);

  const handleVotingStatusUpdate = useCallback(
    (updatedVotingStatus: VotingStatus) => {
      console.log(
        '[INFO] Received voting update from server:',
        updatedVotingStatus,
      );

      // If the server didn't include a timestamp, treat it as old (or ignore).
      // If the new update's timestamp <= local timestamp, we skip it.
      const incomingTimestamp: number =
        typeof updatedVotingStatus.timestamp === 'number'
          ? updatedVotingStatus.timestamp
          : 0;

      if (incomingTimestamp <= votingStatusTimestamp) {
        console.warn(
          '[WARN] Ignoring outdated voting update:',
          updatedVotingStatus,
        );
        return;
      }

      // Otherwise accept this update and update local states
      setVotingOpen(updatedVotingStatus.votingOpen ?? false);
      setVotingStatusTimestamp(incomingTimestamp);
    },
    [votingStatusTimestamp],
  );

  const handleVotingBallotUpdate = useCallback(
    (updatedVotingBallot: VotingBallot) => {
      console.log(
        '[INFO] Received voting update from server:',
        updatedVotingBallot,
      );

      // If the server didn't include a timestamp, treat it as old (or ignore).
      // If the new update's timestamp <= local timestamp, we skip it.
      const incomingTimestamp: number =
        typeof updatedVotingBallot.timestamp === 'number'
          ? updatedVotingBallot.timestamp
          : 0;

      if (incomingTimestamp <= votingBallotTimestamp) {
        console.warn(
          '[WARN] Ignoring outdated voting update:',
          updatedVotingBallot,
        );
        return;
      }

      // Otherwise accept this update and update local states
      setNominations(updatedVotingBallot.nominations ?? []);
      setVotingBallotTimestamp(incomingTimestamp);
    },
    [votingBallotTimestamp],
  );

  /**
   * useEffect Hook: Register and Clean Up Voting Update Listener
   * This effect runs once when the component mounts and sets up a listener for voting updates.
   * It also ensures that the listener is removed when the component unmounts to prevent memory leaks.
   */
  useEffect(() => {
    const unsubscribe = onVotingBallotUpdate(handleVotingBallotUpdate);
    return unsubscribe;
  }, [onVotingBallotUpdate, handleVotingBallotUpdate]);

  useEffect(() => {
    const unsubscribe = onVotingStatusUpdate(handleVotingStatusUpdate);
    return unsubscribe;
  }, [onVotingStatusUpdate, handleVotingStatusUpdate]);

  // Join the game room on mount
  useEffect(() => {
    if (roomCode) {
      joinGameRoom(roomCode);
    }
  }, [joinGameRoom, roomCode]);

  /**
   * **toggleVotingOpen**
   * Toggles the voting state and sends the update via WebSocket.
   */
  const toggleVotingOpen = useCallback(
    (isOpen: boolean) => {
      setVotingOpen((prev) => {
        // If there's no actual change, skip
        if (prev === isOpen) {
          return prev; // do nothing
        }

        // Otherwise update
        return isOpen;
      });

      // Create a new local timestamp
      const newTimestamp: number = Date.now();

      // Update local state so we don't ignore our own update
      setVotingStatusTimestamp(newTimestamp);

      // Send updated data to server
      sendVotingStatusUpdate(roomCode, {
        votingOpen: isOpen,
        timestamp: newTimestamp,
      });
    },
    [roomCode, sendVotingStatusUpdate],
  );

  /**
   * **addVote**
   * Adds a vote from a voter to a nominee and sends the update via WebSocket.
   */
  const addVote = useCallback(
    (voterId: string, nominatedId: string) => {
      setNominations((prev) => {
        const existingIndex = prev.findIndex(
          (nom) => nom.nominatedId === nominatedId,
        );
        let updatedNominations;

        if (existingIndex === -1) {
          // No existing entry => create it
          updatedNominations = [...prev, { nominatedId, voterIds: [voterId] }];
        } else {
          // Modify existing
          updatedNominations = prev.map((nom, i) =>
            i === existingIndex
              ? {
                  ...nom,
                  voterIds: [...new Set([...nom.voterIds, voterId])],
                }
              : nom,
          );
        }

        // Prepare a new timestamp
        const newTimestamp: number = Date.now();
        setVotingBallotTimestamp(newTimestamp);

        console.log('[INFO] Emitting addVote update:', updatedNominations);
        sendVotingBallotUpdate(roomCode, {
          nominations: updatedNominations,
          timestamp: newTimestamp,
        });

        return updatedNominations;
      });
    },
    [roomCode, sendVotingBallotUpdate],
  );

  /**
   * **removeVote**
   * Removes a vote from a voter and sends the update via WebSocket.
   */
  const removeVote = useCallback(
    (voterId: string, nominatedId: string) => {
      setNominations((prev) => {
        const updatedNominations = prev.map((nom) =>
          nom.nominatedId === nominatedId
            ? { ...nom, voterIds: nom.voterIds.filter((id) => id !== voterId) }
            : nom,
        );

        // Prepare a new timestamp
        const newTimestamp: number = Date.now();
        setVotingBallotTimestamp(newTimestamp);

        console.log('[INFO] Emitting removeVote update:', updatedNominations);
        sendVotingBallotUpdate(roomCode, {
          nominations: updatedNominations,
          timestamp: newTimestamp,
        });

        return updatedNominations;
      });
    },
    [roomCode, sendVotingBallotUpdate],
  );

  /**
   * **switchVote**
   * Switches a vote from one nominee to another atomically and sends the update via WebSocket.
   */
  const switchVote = useCallback(
    (
      voterId: string,
      oldNominatedId: string | null,
      newNominatedId: string,
    ) => {
      setNominations((prev) => {
        const updatedNominations = [...prev];

        // Remove the vote from the old nominee if it exists
        if (oldNominatedId) {
          const oldIndex = updatedNominations.findIndex(
            (nom) => nom.nominatedId === oldNominatedId,
          );
          if (oldIndex !== -1) {
            const updatedVoterIds = updatedNominations[
              oldIndex
            ].voterIds.filter((id) => id !== voterId);
            if (updatedVoterIds.length > 0) {
              updatedNominations[oldIndex] = {
                ...updatedNominations[oldIndex],
                voterIds: updatedVoterIds,
              };
            } else {
              // Remove the nomination if no voters remain
              updatedNominations.splice(oldIndex, 1);
            }
          }
        }

        // Add the vote to the new nominee
        const newIndex = updatedNominations.findIndex(
          (nom) => nom.nominatedId === newNominatedId,
        );
        if (newIndex === -1) {
          updatedNominations.push({
            nominatedId: newNominatedId,
            voterIds: [voterId],
          });
        } else {
          updatedNominations[newIndex] = {
            ...updatedNominations[newIndex],
            voterIds: [
              ...new Set([...updatedNominations[newIndex].voterIds, voterId]),
            ],
          };
        }

        // Prepare a new timestamp
        const newTimestamp: number = Date.now();
        setVotingBallotTimestamp(newTimestamp);

        console.log('[INFO] Emitting switchVote update:', updatedNominations);
        sendVotingBallotUpdate(roomCode, {
          nominations: updatedNominations,
          timestamp: newTimestamp,
        });

        return updatedNominations;
      });
    },
    [roomCode, sendVotingBallotUpdate],
  );

  // Inside VotingDetailsProvider
  const resetNominations = useCallback(() => {
    setNominations([]);
    const newTimestamp = Date.now();
    setVotingBallotTimestamp(newTimestamp);

    // Send the reset nominations to the backend
    sendVotingBallotUpdate(roomCode, {
      nominations: [],
      timestamp: newTimestamp,
    });
  }, [roomCode, sendVotingBallotUpdate]);

  // Provide context value
  const contextValue = useMemo(
    () => ({
      votingOpen,
      toggleVotingOpen,
      nominations,
      setNominations,
      addVote,
      removeVote,
      switchVote,
      resetNominations,
      votingStatusTimestamp,
      votingBallotTimestamp,
    }),
    [
      votingOpen,
      toggleVotingOpen,
      nominations,
      addVote,
      removeVote,
      switchVote,
      resetNominations,
      votingStatusTimestamp,
      votingBallotTimestamp,
    ],
  );

  return (
    <VotingDetailsContext.Provider value={contextValue}>
      {children}
    </VotingDetailsContext.Provider>
  );
};

export const useVotingDetails = (): VotingDetailsContextType => {
  const context = useContext(VotingDetailsContext);
  if (!context) {
    throw new Error(
      'useVotingDetails must be used within a VotingDetailsProvider',
    );
  }
  return context;
};
