import { ComponentType } from 'react';
import { GameComponentProps } from '../types';

// Game mappings: IDs or full names to shorthand names
const gameMappings: { [key: string]: string } = {
  '6701bc6c35797c2928318643': 'FEOS', // Finish Each Other's Sandwiches game ID mapped to FEOS
  '6763bad2f4c6d095f2dd91d0': 'EgoManiacs', // Ego Maniacs game ID mapped to EgoManiacs
};

// Utility function to dynamically load a game component using game ID or shorthand
export const loadGameComponent = async (
  gameId: string,
): Promise<ComponentType<GameComponentProps> | null> => {
  const gameName = gameMappings[gameId];

  if (!gameName) {
    console.error(`No game mapping found for ID or name: ${gameId}`);
    return null;
  }

  try {
    const gameComponent = await import(`../games/${gameName}/${gameName}Game`);
    return gameComponent.default as ComponentType<GameComponentProps>;
  } catch (error) {
    console.error(`Error loading game component for ${gameName}:`, error);
    return null;
  }
};
