import React from 'react';
import './Hamburger.css';

type HamburgerIconProps = {
  onClick: () => void;
  size?: number;
  color?: string;
  hoverColor?: string;
};

const HamburgerIcon: React.FC<HamburgerIconProps> = ({
  onClick,
  size = 40,
  color = '#B3B3B3',
  hoverColor = '#d0ff00',
}) => {
  return (
    <button
      className="hamburger-icon-button"
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="none"
        className="hamburger-icon"
      >
        <path
          d="M10.0391 23.6641C9.05469 23.6641 8.28125 22.8906 8.28125 21.9062C8.28125 20.9219 9.05469 20.125 10.0391 20.125H53.9141C54.8984 20.125 55.6953 20.9219 55.6953 21.9062C55.6953 22.8906 54.8984 23.6641 53.9141 23.6641H10.0391ZM10.0391 33.8828C9.05469 33.8828 8.28125 33.1094 8.28125 32.125C8.28125 31.1172 9.05469 30.3203 10.0391 30.3203H53.9141C54.8984 30.3203 55.6953 31.1172 55.6953 32.125C55.6953 33.1094 54.8984 33.8828 53.9141 33.8828H10.0391ZM10.0391 44.125C9.05469 44.125 8.28125 43.3281 8.28125 42.3203C8.28125 41.3359 9.05469 40.5625 10.0391 40.5625H53.9141C54.8984 40.5625 55.6953 41.3359 55.6953 42.3203C55.6953 43.3281 54.8984 44.125 53.9141 44.125H10.0391Z"
          fill={color}
        />
      </svg>
      <style>{`
        .hamburger-icon-button:hover .hamburger-icon path {
          fill: ${hoverColor};
        }
      `}</style>
    </button>
  );
};

export default HamburgerIcon;
