import axiosInstance from '../utils/axiosConfig';

/**
 * Fetches a signed URL for a shared asset from the backend.
 *
 * @param assetName - The name of the shared asset file (e.g., 'trophy-generic-golden.png').
 * @returns A promise that resolves to the signed URL for the asset.
 */
export async function getSharedAsset(assetName: string): Promise<string> {
  try {
    // Send a GET request to fetch the signed URL from the backend
    const response = await axiosInstance.get('/api/shared-asset', {
      params: { assetName }, // Pass query parameters in a structured way
    });

    // Ensure the response contains the expected 'url' field
    if (!response.data || typeof response.data.url !== 'string') {
      throw new Error(
        'Invalid response format: No URL returned for the shared asset',
      );
    }

    // Return the signed URL for the requested asset
    return response.data.url;
  } catch (error: unknown) {
    // Check if the error object has a 'response' property (indicating an HTTP error)
    if (typeof error === 'object' && error !== null && 'response' in error) {
      const responseError = error as {
        response: { status: number; data?: { error?: string } };
      };

      console.error(
        `[ERROR] Failed to fetch shared asset (${assetName}):`,
        `Status: ${responseError.response.status}, Message: ${responseError.response.data?.error || 'Unknown server error'}`,
      );

      throw new Error(
        `Failed to fetch shared asset (${assetName}): ${responseError.response.data?.error || 'Unexpected error'}`,
      );
    }

    // Handle unexpected errors (e.g., network failure, timeouts, etc.)
    console.error(
      `[ERROR] Unexpected error while fetching shared asset (${assetName}):`,
      error,
    );

    throw new Error(`Could not load asset: ${assetName}`);
  }
}
