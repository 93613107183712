import React from 'react';
import './XMark.css';

type XMarkIconProps = {
  onClick: () => void;
  size?: number;
  color?: string;
  hoverColor?: string;
};

const XMarkIcon: React.FC<XMarkIconProps> = ({
  onClick,
  size = 40,
  color = '#B3B3B3',
  hoverColor = '#d0ff00',
}) => {
  return (
    <button
      className="x-mark-button"
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="none"
        className="x-mark-icon"
      >
        <path
          d="M14.0234 50.0781C13.2031 49.2812 13.2266 47.9219 14.0234 47.125L29.0234 32.1016L14.0234 17.1016C13.2266 16.3047 13.2266 14.9688 14.0234 14.1484C14.8203 13.3047 16.2031 13.3281 17 14.1484L32 29.1484L47 14.1484C47.7969 13.3281 49.1562 13.3281 49.9766 14.1484C50.7969 14.9453 50.7734 16.3047 49.9766 17.1016L34.9766 32.1016L49.9766 47.125C50.7734 47.9219 50.7734 49.2578 49.9766 50.0781C49.1797 50.8984 47.7969 50.875 47 50.0781L32 35.0781L17 50.0781C16.2031 50.875 14.8438 50.875 14.0234 50.0781Z"
          fill={color}
        />
      </svg>
      <style>{`
        .x-mark-button:hover .x-mark-icon path {
          fill: ${hoverColor};
        }
      `}</style>
    </button>
  );
};

export default XMarkIcon;
