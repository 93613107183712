import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../utils/googleAnalytics';
import { routesConfig } from '../utils/routesConfig';

const AnalyticsTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Match the current path to the route config
    const matchingRoute = routesConfig.find(({ path }) =>
      new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`).test(location.pathname),
    );

    if (!matchingRoute) {
      console.warn(`No matching route found for path: ${location.pathname}`);
      logPageView('/unknown', 'Unknown Page');
      return;
    }

    const { normalizedPath, pageTitle, extractRoomCode } = matchingRoute;

    // Extract roomCode using the route-specific function, if available
    const roomCode = extractRoomCode
      ? extractRoomCode(location.pathname)
      : undefined;

    console.log('gtag Normalized Path:', normalizedPath);
    console.log('gtag Room Code:', roomCode);

    document.title = pageTitle;

    logPageView(normalizedPath, pageTitle, roomCode);
  }, [location]);

  return null;
};

export default AnalyticsTracker;
