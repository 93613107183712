// App.tsx
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Outlet,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './utils/axiosConfig';
import { SpeedInsights } from '@vercel/speed-insights/react';

import Email from './pages/Email';
import EmailVerify from './pages/EmailVerify';
import Game from './pages/Game';
import GameLibrary from './pages/GameLibrary';
import Home from './pages/Home';
import Player from './pages/Player';
import Playing from './pages/Playing';
import Room from './pages/Room';
import Subscribe from './pages/Subscribe';
import Terms from './pages/Terms';
import About from './pages/About';
import AnalyticsTracker from './components/AnalyticsTracker';

import { CombinedRoomProviders } from './contexts/CombinedRoomProvider';
import { GlobalProvider } from './contexts/GlobalProvider';
import {
  AuthenticatedRoute,
  SubscriptionRoute,
} from './components/ProtectedRoutes/ProtectedRoutes';

// Utility functions to manage cookies (for session management)
const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 86400000).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
};

// Wrapper components for room-specific routes
const RoomWithRoomId = () => {
  const { roomId } = useParams<{ roomId?: string }>();
  if (!roomId) {
    return <div>Error: Room ID is required</div>;
  }
  return (
    <CombinedRoomProviders roomCode={roomId}>
      <Room />
    </CombinedRoomProviders>
  );
};

const GameWithRoomId = () => {
  const { roomId } = useParams<{ roomId?: string }>();
  if (!roomId) {
    return <div>Error: Room ID is required</div>;
  }
  return (
    <CombinedRoomProviders roomCode={roomId}>
      <Game />
    </CombinedRoomProviders>
  );
};

const PlayingWithRoomId = () => {
  const { roomId } = useParams<{ roomId?: string }>();
  if (!roomId) {
    return <div>Error: Room ID is required</div>;
  }
  return (
    <CombinedRoomProviders roomCode={roomId}>
      <Playing />
    </CombinedRoomProviders>
  );
};

const PlayerWithRoomId = () => {
  const { roomId } = useParams<{ roomId?: string }>();
  if (!roomId) {
    return <div>Error: Room ID is required</div>;
  }
  return (
    <CombinedRoomProviders roomCode={roomId}>
      <Player />
    </CombinedRoomProviders>
  );
};

// Main App component that sets up the router and initializes the session ID cookie
const App: React.FC = () => {
  useEffect(() => {
    // Check if a session ID is already set; if not, create one
    const sessionId = getCookie('sessionID');
    if (!sessionId) {
      const newSessionId = uuidv4();
      setCookie('sessionID', newSessionId, 1); // Set the session cookie for 1 day
      console.log('New session ID set:', newSessionId);
    } else {
      console.log('Session ID exists:', sessionId);
    }
  }, []);

  return (
    <Router>
      <GlobalProvider>
        {' '}
        {/* Global contexts wrapping the entire app */}
        <AnalyticsTracker />
        <Routes>
          <Route element={<Outlet />}>
            {/* Routes requiring authentication */}
            <Route element={<AuthenticatedRoute />}>
              <Route path="/subscribe" element={<Subscribe />} />
            </Route>

            {/* Routes requiring authentication and subscription */}
            <Route element={<SubscriptionRoute />}>
              <Route path="/game-library" element={<GameLibrary />} />
            </Route>
          </Route>

          {/* Public routes */}
          <Route path="/" element={<Home />} />
          <Route path="/email" element={<Email />} />
          <Route path="/email-verification" element={<EmailVerify />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/room/:roomId" element={<RoomWithRoomId />} />
          <Route path="/room/:roomId/player" element={<PlayerWithRoomId />} />
          <Route path="/game/:roomId" element={<GameWithRoomId />} />
          <Route path="/game/:roomId/playing" element={<PlayingWithRoomId />} />
        </Routes>
      </GlobalProvider>
      <SpeedInsights />
    </Router>
  );
};

export default App;
